<template>
    <div class="TeamTestList">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div slot="header" class="clearfix">
                    <el-form :inline="true" class="demo-form-inline" :model="teamTestFram" ref="teamTestFram">
                        <el-form-item label="试验类别">
                            <el-input v-model="teamTestFram.shType" placeholder="请输入试验类别查询"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                            <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                        </el-form-item>
                    </el-form>

                    <div class="clearfix">
                        <el-button  class="btn" @click="handleAddTeam" icon="el-icon-circle-plus-outline">新增</el-button>
                    </div>
                </div>
            </div>


            <!-- 列表 -->
            <el-table :data="teamTestList" border  style="width: 100%; font-size: 13px;" v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="shType_text" label="试验类别" align="center"> </el-table-column>
                <el-table-column prop="location" label="试验地点" align="center">
                </el-table-column>
                <el-table-column prop="tester" label="试验人员" align="center">
                </el-table-column>
                <el-table-column prop="testDate" label="试验日期" align="center">
                </el-table-column>
                <el-table-column prop="isRegular_text" label="是否合格" align="center">
                </el-table-column>
                <el-table-column prop="labReportName" label="试验报告" align="center">
                </el-table-column>
                <el-table-column prop="resultDesc" label="试验结果描述" align="center">
                </el-table-column>
                <el-table-column prop="account" label="录入人员" align="center">
                </el-table-column>
                <el-table-column prop="recordTime" label="录入时间" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="修改" @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                        <el-button type="text" size="mini" title="删除" class="delColor" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="teamTestFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="teamTestFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>
import { searchToolTest,delTeamToolTest, getShTypes} from '@/api/teamTest.js'
export default {
    props: {
        tid: {
            type: [String, Number]
        }
    },
    data() {
        return {
            shTypeList: [],
            teamTestList: [],
            teamTestFram: {
                tid: this.tid,
                shType: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false
        }
    },
    mounted() {
        this.loadShTypes()
        this.loadTeamTestInfo()
    },
    methods: {

        handleSizeChange(val) {
            this.teamTestFram.pageSize = val;
            this.teamTestFram.pageNum = 1;
            this.loadTeamTestInfo();
        },
        handleCurrentChange(val) {
            this.teamTestFram.pageNum = val;
            this.loadTeamTestInfo();
        },
        async loadShTypes() {
            await getShTypes().then(res => {
                if (res.code === '000000') {
                    this.shTypeList = res.data
                }
            })
        },
        loadTeamTestInfo() {
            this.loading = true
            searchToolTest(this.teamTestFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.teamTestList = res.t.list
                        let count = 1;
                        this.teamTestList.forEach(item => {
                            item.seq = count++
                            item.isRegular_text = item.isRegular === 0 ? '合格' : '不合格'
                            const index =  this.shTypeList.findIndex(item2=>item.shType===item2.pKey);
                            item.shType_text= this.shTypeList[index].pValue
                        })
                    }

                }, 500)
            })
        },

        handleAddTeam() {
            this.$router.push({
                name: 'team-exper-test-create',
                params: {
                    tid: this.tid
                }
            })
        },
        handleEdit({ id, tid }) {
            this.$router.push({
                name: 'team-exper-test-edit',
                params: {
                    tid: tid,
                    id: id
                }
            })
        },
        handleDetail({ id, tid }){
            this.$router.push({
                name: 'team-exper-test-detail',
                params: {
                    tid: tid,
                    id: id
                }
            })
        },
        del({id,seq}) {
            this.$confirm('是否删除序号为“'+seq+'”的试验报告？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delTeamToolTest(id).then(res => {
                    if (res.code === '000000') {
                        this.loadTeamTestInfo()
                        this.$message.success('删除成功')
                        
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },

        //搜索
        handleSearch() {
            this.loadTeamTestInfo(this.teamTestFram);
        },
        //重置
        handleReset() {
            this.teamTestFram = {
                tid: this.tid,
                shType: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.loadTeamTestInfo(this.teamTestFram);
        }
    }
}
</script>



<style lang="scss" scoped>
.TeamTestList {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }

        .clearfix {
            text-align: left;
            margin-bottom: 20px;
        }
    }

    // .el-pagination,
    // .clearfix {
    //     text-align: left;
    // }

    .delColor {
        color: red;

    }

}
</style>